<template>
    <div class="inner_pages" id="product_index">
        <sidebar-component v-model="showSidebar"/>
        <div class="respective_content" id="checkout-products">
            <div class="acton_header">
                <div class="left_section">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Products</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                    <button type="button" v-if="companyUserCan('create', 'checkouts')" class="add_btn pointer" @click="handleAddProduct()"><i class="fas fa-plus"></i>Create Product</button> -->
                </div>
                <div class="right_section flex">
                    <label for="product_archived" class="switch_option capsule_btn m-0 border-0">
                        <h5 class="mr-2">Archived</h5>
                        <input type="checkbox" id="product_archived" :true-value="1" :false-value="0" v-model="archived" hidden>
                        <div><span></span></div>
                    </label>
                    <label for="order_bump" class="switch_option capsule_btn mb-0 ml-3">
                        <h5 class="mr-2">Show Order Bump</h5>
                        <input type="checkbox" id="order_bump" :true-value="1" :false-value="0" v-model="orderBump" hidden>
                        <div><span></span></div>
                    </label>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortProduct = !sortProduct;" v-tooltip="`Sort Products Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li @click="importProduct = !importProduct" v-tooltip="`Import a shared product`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer" v-if="companyUserCan('create', 'checkouts')" @click="handleAddProduct()"><i class="fas fa-plus-circle"></i>Create Product</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedProduct.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedProduct.length ? `${selectedProduct.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="companyUserCan('delete', 'checkouts') && selectedProduct.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li @click="handleArchiveUnarchiveProduct(selectedProduct, archived ? 0 : 1)">{{ archived ? 'Unarchive' : 'Archive' }}</li>
                                    <li v-if="archived == 1" class="danger" @click="handleDeleteProduct(selectedProduct, archived ? 0 : 1)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the products feature</li>
                                </ul>
                            </div>
                        </li>
                        <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="search"/>
                            <button class="search_btn" @click="searchField = !searchField;">
                                <i class="fas fa-search"></i>
                            </button>
                        </li> -->
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList" v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) of filters" @click="sortingFilter(filter)" :key="f">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="product-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-show="productLoader"><quote-loader /></div>
                <div class="action_content" v-show="!productLoader">
                    <asset-folder v-model="assetFolder" title="Product" asset-type="checkout-products" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :section-breadcrumb="folderBreadcrumb['checkout-products']"/>
                    <ul class="playbook_list" v-if="products.data">
                        <li v-for="(product, p) in products.data" :key="p">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedProduct.includes(product.id) }">
                                    <img v-if="product.thumbnail" :src="product.thumbnail" class="img">
                                    <img  v-else src="@/assets/images/thumb/default-cover-3-2.svg" class="img">
                                    <span class="card_edit" v-if="companyUserCan('update', 'checkouts')" @click="editThumbnail = true; activeProduct = product;"><i class="fas fa-pencil-alt"></i></span>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`product-${product.id}`" class="checkbox">
                                                <input type="checkbox" :id="`product-${product.id}`" :value="product.id" v-model="selectedProduct" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ product.orders_count ? product.orders_count : 0 }} Total Sales</li>
                                        <li>${{ product.orders_sum_total ? parseFloat(product.orders_sum_total).toFixed(2) : 0 }} Total Revenue</li>
                                        <li>{{ product.views_count }} Page View</li>
                                        <li>{{ product.views_count ? ((product.orders_count * 100) / product.views_count).toFixed(0) : 0 }}% Page conversion rate</li>
                                    </ul>
                                </div>
                                <div v-tooltip="product.name ? product.name : 'Untitled Product'">
                                    <a class="item_ttl pointer" v-if="companyUserCan('update', 'checkouts')" @click="editProduct = true; activeProduct = product;">{{ product.name }}</a>
                                    <a class="item_ttl pointer" v-else>{{ product.name }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="tags" v-if="product.product_mode == 0">Disabled</div>
                                    <div class="tags" v-else-if="product.product_mode == 1">Test Mode</div>
                                    <div class="tags live" v-else-if="product.product_mode == 2 && product.stripe_product_id">Live Product</div>
                                    <div class="tags queued" v-else-if="product.product_mode == 2 && !product.stripe_product_id">In Queue</div>
                                    <ul>
                                        <li @click="toggleProductLink(product)"><i class="fas fa-link"></i></li>
                                        <!-- <li @click="handleDuplicateProduct(product.id)"><i class="far fa-copy"></i></li> -->
                                        <!-- <li @click="handleDeleteProduct([product.id])"><i class="far fa-trash-alt danger"></i></li> -->
                                        <li @click="moreOption(product.id)">
                                            <i class="fas fa-ellipsis-v"></i>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  product.id}">
                                                <li v-if="companyUserCan('update', 'checkouts')" @click="editProduct = true; activeProduct = product;">Edit Product</li>
                                                <li v-if="companyUserCan('update', 'checkouts')" @click="editThumbnail = true; activeProduct = product;">Edit Thumbnail Image</li>
                                                <li v-if="companyUserCan('update', 'checkouts')" @click="renameProduct = true; activeProduct = product;">Rename Product</li>
                                                <li @click="toggleProductLink(product)">Product Link</li>
                                                <li v-if="archived == 0" @click="handleShareProduct(product)">Share Product</li>
                                                <li v-if="companyUserCan('create', 'checkouts') && archived == 0" @click="handleShareProductWithAccount(product)">Share to Location</li>
                                                <li v-if="archived == 0" @click="moveProduct = true; activeProduct = product;">Move to Folder</li>
                                                <li v-if="companyUserCan('create', 'checkouts') && archived == 0" @click="handleDuplicateProduct(product.id)">Duplicate</li>
                                                <li v-if="companyUserCan('delete', 'checkouts')" @click="handleArchiveUnarchiveProduct([product.id], archived ? 0 : 1)">{{ archived ? 'Unarchive' : 'Archive' }} Product</li>
                                                <li v-if="companyUserCan('delete', 'checkouts') && archived == 1" class="danger" @click="handleDeleteProduct([product.id])">Delete Product</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_section" v-if="!products.data">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Looks like you don't have any Products yet. Click the play button to get started.</h1>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ products.from ? products.from : 0 }} - {{ products.to ? products.to : 0 }} of {{ products.total ? products.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="products.total">
                    <pagination v-model="page" :pages="products.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                        <li @click="sortProduct = !sortProduct;" v-tooltip="`Sort Products Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li @click="importProduct = !importProduct" v-tooltip="`Import a shared product`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                    <label for="product_archived" class="switch_option capsule_btn mb-0 mt-3 border-0">
                        <h5 class="mr-2">Archived</h5>
                        <input type="checkbox" id="product_archived" :true-value="1" :false-value="0" v-model="archived" hidden>
                        <div><span></span></div>
                    </label>
                    <label for="order_bump" class="switch_option capsule_btn m-0 border-0">
                        <h5 class="mr-2">Show Order Bump</h5>
                        <input type="checkbox" id="order_bump" :true-value="1" :false-value="0" v-model="orderBump" hidden>
                        <div><span></span></div>
                    </label>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedProduct.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedProduct.length ? `${selectedProduct.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="companyUserCan('delete', 'checkouts') && selectedProduct.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li @click="handleArchiveUnarchiveProduct(selectedProduct, archived ? 0 : 1)">{{ archived ? 'Unarchive' : 'Archive' }}</li>
                                    <li v-if="archived == 1" class="danger" @click="handleDeleteProduct(selectedProduct, archived ? 0 : 1)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2" v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) of filters" @click="sortingFilter(filter)" :key="f">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <new-product v-model="newProduct" :refresh-product="refreshProduct"></new-product>
        <edit-thumbnail v-model="editThumbnail" :selected-product="activeProduct" :refresh-product="refreshProduct"></edit-thumbnail>
        <asset-link v-model="productLink" title="Product" :selected-asset="activeProduct" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
        <order-bump v-model="orderBump"></order-bump>
        <edit-product v-model="editProduct" :selected-product="activeProduct" ></edit-product>
        <sort-asset v-model="sortProduct" title="Product" asset-type="checkout-products" :update-sorting="sortingFilter" :folder-id="params.folder_id" />
        <import-share-link v-model="importProduct" title="Product" :refresh-asset="refreshProduct" />
        <share-component v-model="shareProduct" title="Product" asset-title="checkout-products" :selected-asset="activeProduct" />
        <move-asset v-model="moveProduct" title="Product" asset-type="checkout-products" :selected-asset="activeProduct" :refresh-asset="refreshProduct" />
        <rename-product v-model="renameProduct" :active-product="activeProduct" />
        <share-product-with-account v-model="shareProductWithAccount" :selected-product="activeProduct" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const MoveAsset = defineAsyncComponent(() => import('@/components/MoveAsset'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))
    const OrderBump = defineAsyncComponent(() => import('@/pages/checkout/components/product/OrderBump'))
    const NewProduct = defineAsyncComponent(() => import('@/pages/checkout/components/product/NewProduct'))
    const EditProduct = defineAsyncComponent(() => import('@/pages/checkout/components/product/EditProduct'))
    const EditThumbnail = defineAsyncComponent(() => import('@/pages/checkout/components/product/EditThumbnail'))
    const RenameProduct = defineAsyncComponent(() => import('@/pages/checkout/components/product/RenameProduct'))
    const ShareProductWithAccount = defineAsyncComponent(() => import('@/pages/checkout/components/product/ShareProductWithAccount'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Checkout Product',

        data () {
            return {
                newProduct: false,
                actionList: false,
                actionList2: false,
                filterTitle: 'Date Created',
                filters: [
                    { title: 'Date Created', filter: 'date_creted' },
                    { title: 'Last Modified', filter: 'last_modified' },
                    { title: 'Price', filter: 'price' },
                    { title: 'Recurring', filter: 'recurring' },
                    { title: 'Split Pay', filter: 'split_pay' },
                    { title: 'One-time', filter: 'one_time' },
                    { title: 'Live Product', filter: 'live_product' },
                    { title: 'Test Mode', filter: 'test_mode' },
                ],
                isTyping: false,
                orderBump: 0,
                editThumbnail: false,
                productLink: false,
                selectedProduct: [],
                activeProduct: {},
                tutorialList: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/a18a433094ca4f988faa3f7fbed5917d?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                showContent: false,
                editProduct: false,
                searchField: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                importProduct: false,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                assetFolder: false,
                assetFolderWatcher: 0,
                sortProduct: false,
                isLastBreadcrumb: false,
                search: '',
                archived: 0,
                perPageFilter: 6,
                moveProduct: false,
                moreOptionDropdown: false,
                shareProduct: false,
                isMoreOption: false,
                renameProduct: false,
                page: 1,
                shareProductWithAccount: false,
                mobile_filter: false,
                showSidebar: false,
            }
        },

        watch:{
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val && vm.search != null) {
                    if (vm.search.length >= 2 || vm.search.length == 0) {
                        vm.setDashboardParams({ key: 'page', value: 1 });
                        vm.getProducts(vm.params);
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.selectedProduct = [];

                vm.getProducts(vm.params);
            },

            archived (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'is_archived', value: val });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.getProducts(vm.params);
            },

            checkoutAuthToken (authToken) {
                const vm = this;

                if (authToken) {
                    vm.getProducts(vm.params);
                }
            },

            editProduct (val) {
                const vm = this;

                if (!val) {
                    vm.getProducts(vm.params);
                }
            },
        },

        components: {
            AssetLink,
            SortAsset,
            OrderBump,
            MoveAsset,
            NewProduct,
            AssetFolder,
            EditProduct,
            EditThumbnail,
            VideoTutorial,
            RenameProduct,
            ShareComponent,
            ImportShareLink,
            SidebarComponent,
            ShareProductWithAccount,
        },

        computed: {
            ...mapState ({
                products: state => state.checkoutModule.products,
                productLoader: state => state.checkoutModule.productLoader,
                companySettings: state => state.checkoutModule.companySettings,
                checkoutAuthToken: state => state.checkoutModule.checkoutAuthToken,
                isArchived: state => state.folderModule.isArchived,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
                params: state => state.checkoutModule.params,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0;
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.folder_id ? vm.folder_id : 0;

            vm.setIsPreviousFolder(false);

            if (vm.checkoutAuthToken) {
                vm.getProducts(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            document.getElementById('product_index').onclick = function () {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }

            // if (!Object.keys(vm.companySettings).length) {
            //     vm.getCompanySettings();
            // }
        },

        methods: {
            ...mapActions({
                getProducts: 'checkoutModule/getProducts',
                deleteProduct: 'checkoutModule/deleteProduct',
                duplicateProduct: 'checkoutModule/duplicateProduct',
                archiveUnarchiverProduct: 'checkoutModule/archiveUnarchiverProduct',
                getCompanySettings: 'checkoutModule/getCompanySettings',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setDashboardParams: 'checkoutModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'checkoutModule/RESET_DASHBOARD_PARAMS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
            }),

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle  = filter.title;
                // vm.filter       = filter.filter;

                vm.setDashboardParams({ key: 'filter', value: filter.filter });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.setDashboardParams({ key: 'page', value: 1 });

                vm.getProducts(vm.params);
            },

            togglePerPageFilter () {
                const vm     = this;
                const filter = vm.$refs['product-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: page });
                vm.getProducts(vm.params);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['product-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            refreshProduct () {
                const vm = this;

                vm.search = '';
                vm.selectedProduct  = [];

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderParams.parent_id ? vm.folderParams.parent_id : null });

                vm.getProducts(vm.params);
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshProduct();
            },

            handleDeleteProduct (ids) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ids.length > 1 ? 'these products' : 'this product'}. Please type DELETE to remove ${ids.length > 1 ? 'these products' : 'this product'}.`, 'Delete');

                options.confirmButtonColor = '#eb1414';
                options.input = 'text';
                options.inputAttributes = { placeholder: 'Enter DELETE' };
                options.inputValidator  = (input) => {
                                              if (/^[A-Z]/.test(input) == false) {
                                                  return 'Please enter all capital letters';
                                              }

                                              if (input !== 'DELETE') {
                                                  return `Please type DELETE to delete ${ ids.length > 1 ? 'products' : 'product' }`;
                                              }

                                              Swal.enableButtons();
                                          }

                options.preConfirm      = function () {
                                              return vm.deleteProduct({ ids }).then((result) => {
                                                  vm.refreshProduct();
                                              });
                                          };

                options.willOpen        = function () {
                                              const button  = Swal.getConfirmButton();
                                              const input   = Swal.getInput()

                                              if (button) {
                                                  button.disabled = true;
                                              }

                                              input.oninput = (event) => {
                                                  const text = event.target.value;

                                                  if (/^[A-Z]/.test(text) == true && text === 'DELETE') {
                                                      Swal.enableButtons();
                                                  } else {
                                                      button.disabled = true;
                                                  }
                                              }
                                          }

                Swal.fire(options);
            },

            handleDuplicateProduct (id) {
                const vm = this;

                let form = { product_id: id }

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate the selected product. Please enter the product name and continue.`, 'Duplicate');

                options.input = 'text';
                options.inputAttributes = { placeholder: 'ex: New Product' };
                options.inputValidator  = (input) => {
                                              if (input) {
                                                  form.name = input;
                                              } else {
                                                  return 'Please enter product name';
                                              }
                                          };

                options.preConfirm = function() {
                                        return vm.duplicateProduct(form).then((result) => {
                                            if (result) {
                                                vm.search = '';
                                                vm.setDashboardParams({ key: 'page', value: 1 });
                                                vm.setDashboardParams({ key: 'search', value: '' });
                                                vm.getProducts(vm.params);
                                            } else {
                                                return false;
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleArchiveUnarchiveProduct (ids, archive = 1) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${ archive ? 'archive' : 'unarchive'} the selected product.`, archive ? 'Archive' : 'Unarchive');

                options.preConfirm = function() {
                                        return vm.archiveUnarchiverProduct({ ids, archive }).then((result) => {
                                            if (result) {
                                                vm.search = '';
                                                vm.selectedProduct = [];
                                                vm.setDashboardParams({ key: 'page', value: 1 });
                                                vm.setDashboardParams({ key: 'search', value: '' });
                                                vm.getProducts(vm.params);
                                            } else {
                                                return false;
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            toggleProductLink (product) {
                const vm = this;

                vm.activeProduct = JSON.parse(JSON.stringify(product));
                vm.activeProduct.share_link = 'http://' + vm.companySettings.subdomain + '.' + process.env.VUE_APP_CHECKOUT_URL + '/' + product.slug;
                vm.productLink = true;
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },

            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            handleShareProduct (product) {
                const vm = this;

                if (product.share_count == 0) {
                    vm.shareProduct = true;
                    vm.activeProduct = product;
                } else if (product.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            handleShareProductWithAccount (product) {
                const vm = this;

                if (product.share_count == 0) {
                    vm.shareProductWithAccount = true;
                    vm.activeProduct = product;
                } else if (product.share_count == 1) {
                    const options = Helper.swalWarningOptions('STOP!', `Do you own this content you are trying to share with another Thrive Coach user? This feature is for sharing a feature with another Thrive Coach user not assigning it to your contacts.`);

                    Swal.fire(options);
                }
            },

            handleAddProduct () {
                const vm = this;

                if (vm.companySettings.currency) {
                    vm.newProduct = true;
                } else {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `You have not configured your marketplace settings. Please configure your settings before creating your first product.`));
                }
            }
        }
    }
</script>

<style scoped>
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 0 !important;
        display: none;
    }
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    
    .result_wpr.new{
        overflow: visible;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .right_section ul{
        margin-left: 15px;
    }

    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list > li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }
    .plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics {
        opacity: 1;
    }
    .card_edit {
        position: absolute;
        right: -30px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
        font-size: 14px;
        transition: all 0.3s ease-in-out;
    }
    .plybook_item .item_img:hover .card_edit{
        right: 15px;
    }
    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }
    .plybook_item .item_info .tags{
        padding: 4px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #999;
        color: #fff;
        border-radius: 3px;
    }
    .plybook_item .item_info .tags.live{
        background: #2f7eed;
    }
    .plybook_item .item_info > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info > ul > li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .section_header .settings {
        margin-left: 20px;
        cursor: pointer;
    }
    .section_header .settings i {
        font-size: 22px;
        color: #9f9e9e;
    }

    .plybook_item .item_info .tags.queued {
        background: #f2a31d;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    @media(max-width: 1350px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn), .acton_header .right_section .switch_option{
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    @media(min-width: 1351px){
        .header_filter{
            display: none;
        }
    }
    /* @media(max-width: 1350px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section ul{
            margin-left: auto;
        }
    }
    @media(max-width: 1199px){
        .bar_menu {
            display: block;
        }
    }
    @media(min-width: 992px) and (max-width: 1199px){
        .acton_header{
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
        }
        .right_section{
            width: 100%;
            flex-wrap: wrap;
            row-gap: 15px;
        }
        .right_section ul{
            margin-left: auto;
        }
    }
    @media(max-width: 850px){
        .acton_header{
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
        }
        .right_section{
            width: 100%;
            flex-wrap: wrap;
            row-gap: 15px;
        }
        .right_section ul{
            margin-left: auto;
        }
    }
    @media(max-width: 600px){
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section{
            margin-top: 5px;
        }
        .acton_header .right_section ul{
            width: 100%;
        }
        .acton_header .right_section .switch_option:first-of-type{
            margin-right: auto !important;
        }
        .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
    }
    @media(max-width: 450px){
        .right_section ul{
            width: 100%;
        }
    } */
</style>
